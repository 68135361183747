<template>
  <v-sheet :height="isWidget ? '' : ''" :class="isWidget ? 'cust-widj-calendar cust-calendar' : 'cust-page-calendar cust-calendar elevation-1'">
    <v-toolbar
      flat
      dense
      class="cust-calendar-toolbar"
      height="39"
      v-if="!isWidget"
    >
      <v-btn
        fab
        text
        x-small
        color="grey darken-2"
        @click="prev"
      >
        <v-icon small>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn
        fab
        text
        x-small
        color="grey darken-2"
        @click="next"
      >
        <v-icon small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar || calendarObj.length">
        {{ $moment(calendarObj.lastStart.date).format('DD.MM.YYYY') }} 
        <template v-if="status !== 'CalendarToday'">- {{ $moment(calendarObj.lastEnd.date).format('DD.MM.YYYY') }}</template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="status-panel">
        <v-chip-group active-class="active-panel-item" mandatory column>

          <v-chip 
              class="ma-1"
              small
              label
          >
              {{ $t('Сегодня') }}
              <!--<span v-if="moreOrEqualZero(item.count)"> {{item.count}} </span>-->
          </v-chip>
          <v-chip 
              class="ma-1"
              small
              label
          >
              {{ $t(' На_этой_неделе') }}
              <!--<span v-if="moreOrEqualZero(item.count)"> {{item.count}} </span>-->
          </v-chip>
          <v-chip 
              class="ma-1"
              small
              label
          >
              {{ $t(' В_этом_месяце') }}
              <!--<span v-if="moreOrEqualZero(item.count)"> {{item.count}} </span>-->
          </v-chip>
        </v-chip-group>
      </div>
    </v-toolbar>
    <v-calendar
      v-model="focus"
      ref="calendar"
      :events="events"
      color="teal"
      :type="status == 'CalendarMonth' ? 'month' : (status == 'CalendarWeek' ? 'week' : (isWidget ? 'custom-weekly' : 'day'))"
      :weekdays="getPeriod"
      :weekday-format="myDayFormat"
      :event-margin-bottom="0"
      @click:event="showEvent"
      first-time="09:00"
      :short-intervals="false"
    >
      <template v-slot:day-label="{ date }">
        <span :class="$moment().format('D') == $moment(date).format('D') ? 'day-number today-number' : 'day-number'">{{ $moment(date).format('D') }}</span>
      </template>
      <template v-slot:event="{ event, eventParsed }">
        <div class="calendar-event-content" v-tooltip.top-center="event.name">
          {{ eventParsed.start.time }} - {{ eventParsed.end.time }}
          / 
          {{ event.name }}
        </div>
      </template>
    </v-calendar>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
      min-width="400px"
      max-width="400px"
    >
      <v-card
        color="grey lighten-4"
        flat
        min-width="400px"
        max-width="400px"
      >
        <v-toolbar
          :color="selectedEvent.color"
          dark
          dense
          flat
        >
          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="wrapperFormModal">
          <v-card flat>
            <v-card-text>
              <v-form>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4">
                        <label class="f-label"> {{$t("Начало")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="8">
                        <div class="onlyReadData">{{ $moment(selectedEvent.start).format('DD.MM.YYYY HH:SS') }}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4">
                        <label class="f-label"> {{$t("Окончание")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="8">
                        <div class="onlyReadData">{{ $moment(selectedEvent.end).format('DD.MM.YYYY HH:SS') }}</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4">
                        <label class="f-label"> {{$t("Расположение")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="8">
                        <div class="onlyReadData">За столом</div>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="4">
                        <label class="f-label"> {{$t("Напоминание")}} </label>
                    </v-col>
                    <v-col  cols="12" sm="12" md="8">
                        <div class="onlyReadData">15 мин.</div>
                    </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

            <v-btn 
              color="cyan" 
              text
              depressed
            >
              {{$t("Редактировать")}}
          </v-btn>
          
          <v-btn
            color="blue-grey" 
            text
            depressed 
            @click="selectedOpen = false"
          >
            {{$t("Отмена")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-sheet>
</template>

<script>
import i18n from '@/i18n';
export default {
  props: {
    isWidget: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: null
    }
  },
  data: () => ({
    focus: '',
    calendarObj: {},
    events: [
      {
        name: 'Собрание',
        color: 'green',
        start: '2023-08-05 11:00',
        end: '2023-08-05 12:00',
      },
      {
        name: 'Заседание',
        color: 'green',
        start: '2023-08-06 11:00',
        end: '2023-08-07 12:00',
      },
      {
        name: 'Совещание',
        color: 'orange',
        start: '2023-08-07 12:30',
        end: '2023-08-07 13:30',
      },
    ],
    weekDaysLocalize: [i18n.t("Вс"), i18n.t("Пн"), i18n.t("Вт"), i18n.t("Ср"), i18n.t("Чт"), i18n.t("Пт"), i18n.t("Сб")],
    //для открытия события
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
  }),
  computed: {
    getPeriod () {
      if (this.isWidget)
        return [this.$moment().day(), this.$moment().add(1, 'days').day()];
      else if (this.status != null)
        switch (this.status) {
            case 'CalendarToday':
              return [this.$moment().day()];
            case 'CalendarDays':
              return [this.$moment().day(), this.$moment().add(1, 'days').day(), this.$moment().add(2, 'days').day()];
            case 'CalendarWeek':
              return [1,2,3,4,5,6,0];
            default:
                return [1,2,3,4,5,6,0];
        }
      else
        return [1,2,3,4,5,6,0];  
    }
  },
  methods: {
    myDayFormat(day) {
      //console.log(day);
      return this.weekDaysLocalize[day.weekday];
    },
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    prev () {
      this.$refs.calendar.prev();
    },
    next () {
      this.$refs.calendar.next();
    },
  }, 
  mounted () {
    this.calendarObj = this.$refs?.calendar;
  },
  watch: {
    status: {
      handler () {
          this.focus = '';
      },
      deep: true,
    },
  },
}
</script>
<style scoped>
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}
</style>